.form {
  padding-left: 50px;
  padding-right: 50px;

  @media(max-width: 768px) {
    padding: 0 24px;
  }
}

.submit {
  margin-top: 20px;
}

.priceInput {
  margin-bottom: 24px;
}

.awaiting {
  padding: 20px;
  text-align: center;
  font-size: 20px;
}