@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}
.advancedOptionsButton {
  border: none;
    font-size: 12px;
    padding: 0;
}
@media (max-width:1022px){
  .pricePanel {
    position: fixed;
    bottom: 0;
    width: 100vw;
    left: 0;
    background-color: var(--matterColorLight);
    display: flex;
    flex-direction: column;
    box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.05);

  }
  .mobileTop {width: 100vw; left:0; display: flex; align-items: center; justify-content: space-around; padding: 0 20px}
  .buttonContainer{width: 50%!important}
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.pricePanel {
  display: flex;
  padding: 20px;
  justify-content: center;
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  composes: h4 from global;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  margin:20px 0px;
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
  }
}

/* Alert */

.my-actions { margin: 0 2em; }
.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }

.right-gap {
  margin-right: auto;
}