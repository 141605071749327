.tooltip {
    position: relative;
    display: inline-block;
    }

.tooltip .tooltipText {
    visibility: hidden;
    font-size: 16px;
    text-align: center;
    width: 260px;
    position: absolute;
    bottom: 150%;
    left: -160px;
    background: var(--matterColorBright);
    padding: 5px 10px;
    opacity: 0;
    color: #000;
    z-index: 5;
    border-radius: 5px;
    transition: 100ms opacity;
    box-shadow: var(--boxShadowPopup);
    }

.tooltip:hover .tooltipText {
    visibility: visible;
    opacity: 1;
    color: #000;

    }