
.root {
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 42px 24px;
  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    padding: 0 24px 33px 24px;
  }

  @media (--viewportLarge) {
    border-top: none; 
  }
}

#socials{min-height:100px; width:100%; display: flex;}
.socialIcons{display:inline-flex; width:100%; justify-content:center; align-items: center;}
.socialIcon{ border-radius: 100px; padding: 10px;}
.socialImg{border-radius:100px; width:50px; height: 50px; filter: invert(44%) sepia(5%) saturate(600%) hue-rotate(251deg) brightness(95%) contrast(91%);}
.socialImg:hover{filter:none;}

.gritty{min-height:100px; width:100%; display: flex; align-items:center; justify-content: center; font-weight:bolder;}
.grittyTitle{max-width: 100px;}


.liveNation, .tunecore {
  margin: 0px 20px;
  max-width: 100px;

}
.subLinkGroup, .subLinkGroupSocials{
  display:flex;
  justify-content: space-evenly;
  max-width: 80%;
  margin:auto;
  align-items: center;
  }

  .subLinkGroupSocials{
    display:flex;
    justify-content: space-evenly;
    max-width: 30%;
    margin:auto;
    align-items: center;
    padding-bottom: 20px;
    }