.tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
  }
  
  .tabHeader {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    display: none;
  }
  
  .tab {
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    box-shadow: var(--boxShadowButton);
  }
  
  .tab.active {
    border: solid var(--marketplaceColor) 2px;
  }
  
  .tabContent {
    margin-top: 20px;
  }
  
  .tabContent > div {
    display: none;
  }
  
  .tabContent > div:first-child {
    display: block;
  }
  